import { Link } from 'react-router-dom';
// COMPONENTS
import Logo from '../../../components/Logo';

// HOOKS
import useHideElementOnMount from '../../../hooks/useHideElementOnMount';

const SeasonIsClosed = () => {
	useHideElementOnMount('header');
	useHideElementOnMount('footer');

	const currentEnurm = 2025;
	const waitingListLink = 'https://mailchi.mp/prepmed/enurm2026';

	return (
		<div className='bg-brand-25 min-h-screen'>
			<div className='px-8 pt-10 bg-brand-25 w-full '>
				<Logo useDarkLogo={true} classes='h-10' />
			</div>
			<div className='flex flex-col items-center text-center mt-12'>
				<h3>Inscripción Cerrada</h3>
				<div className='my-8 w-3/4 md:w-1/2'>
					<p className='mb-4'>
						Las inscripciones para prepararte a tomar el ENURM {currentEnurm} ya
						están <strong>cerradas</strong>.
					</p>
					<p className='mb-4'>
						En este momento estamos completamente enfocados en brindarles la
						mejor experiencia posible a nuestros estudiantes. Si tienes alguna
						pregunta por favor ve a <Link to='/contactanos'>Contactanos</Link>.
					</p>
					<hr className='my-10' />
					<p className='mb-4'>
						Si quieres prepararte para el ENURM {currentEnurm + 1} con nosotros,
						lo mejor es anotarte en nuestra lista de espera para recibir
						notificaciones de apertura visitando este enlace:
					</p>
				</div>
				<a
					className='bg-brand-500 text-white px-4 py-2 rounded flex items-center'
					href={waitingListLink}>
					<i className='material-icons mr-2'>send</i>
					Lista de Espera ENURM {currentEnurm + 1}
				</a>
			</div>
		</div>
	);
};
export default SeasonIsClosed;
