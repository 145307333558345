import Swal from 'sweetalert2';
import useUserContext from '../../../api/UserContext';
import MainSection from '../../../layout/app/dashboard/MainSection';

const UserProfile = () => {
	const { currentUser, changeUserEmail, updateExistingUser } = useUserContext();

	if (currentUser === null) return null;

	const handleNameEdit = (
		field: string,
		currentValue: string | null = null
	) => {
		const inputValue = currentValue;
		let fieldName: string;
		switch (field) {
			case 'firstName':
				fieldName = 'primer nombre';
				break;
			case 'middleName':
				fieldName = 'segundo nombre';
				break;
			case 'lastName':
				fieldName = 'apellido';
				break;
			case 'secondLastName':
				fieldName = 'segundo apellido';
				break;
			case 'email':
				fieldName = 'correo electrónico';
				break;
			default:
				fieldName = 'primer nombre';
				break;
		}

		Swal.fire({
			title: 'Actualiza tus datos',
			html: `Al someter el cambio estarías actualizando tu <b>${fieldName}</b>, presiona "Actualizar" para confirmar.`,
			icon: 'warning',
			allowOutsideClick: true,
			allowEscapeKey: true,
			allowEnterKey: true,
			showCancelButton: true,
			confirmButtonText: 'Actualizar',
			input: 'text',
			inputValue,
			inputPlaceholder: `Ingresa tu ${fieldName}`,
			inputValidator: (value: string) => {
				if (value === '') {
					return '¡Debes escribir algo!';
				}
			},
			customClass: {
				confirmButton: 'bg-brand-500 hover:bg-brand-700',
				cancelButton: 'bg-red-500 hover:bg-red-600'
			}
		})
			.then(async (result) => {
				if (result.isConfirmed) {
					if (result.value !== '') {
						const uid = currentUser.uid ?? '';
						const resp = await updateExistingUser(uid, field, result.value);
						if (resp) {
							await Swal.fire({
								title: '¡Listo!',
								text: `Tu ${fieldName} ha sido actualizado.`,
								icon: 'success',
								customClass: {
									confirmButton: 'bg-brand-500 hover:bg-brand-700'
								}
							});
						} else {
							await Swal.fire({
								title: 'Error',
								text: `No se pudo actualizar tu ${fieldName}.`,
								icon: 'error',
								customClass: {
									confirmButton: 'bg-brand-500 hover:bg-brand-700'
								}
							});
						}
					} else {
						await Swal.fire({
							title: 'Operación cancelada',
							text: 'No se realizaron cambios.',
							icon: 'info',
							customClass: {
								confirmButton: 'bg-brand-500 hover:bg-brand-700'
							}
						});
					}
				}
			})
			.catch(async (error) => {
				console.error('Error updating user name: ', error);
				await Swal.fire(
					'Operación cancelada',
					'No se realizaron cambios.',
					'info'
				);
			});
	};

	const handleEmailChange = () => {
		Swal.fire({
			title: 'Actualiza tus datos',
			text: 'Al cambiar tu correo electrónico tendrás que re-iniciar sesión. Si deseas contunuar, ingresa el nuevo correo y presiona "Actualizar" para confirmar.',
			icon: 'warning',
			allowOutsideClick: true,
			allowEscapeKey: true,
			allowEnterKey: true,
			showCancelButton: true,
			confirmButtonText: 'Actualizar',
			input: 'email',
			// inputValue: currentUser.email,
			inputPlaceholder: 'Ingresa tu nuevo correo',
			inputValidator: (value: string) => {
				// const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
				const emailRegex = /^[\S]+@[\S]+\.[\S]+$/;
				if (!emailRegex.test(value)) {
					return '¡Debes escribir un correo válido!';
				}
			},
			customClass: {
				confirmButton: 'bg-brand-500 hover:bg-brand-700',
				cancelButton: 'bg-red-500 hover:bg-red-600'
			}
		})
			.then(async (result) => {
				if (result.isConfirmed) {
					if (result.value !== '') {
						const uid = currentUser.uid ?? '';
						changeUserEmail(uid, result.value);
					} else {
						await Swal.fire({
							title: 'Operación cancelada',
							text: 'No se realizaron cambios.',
							icon: 'info',
							customClass: {
								confirmButton: 'bg-brand-500 hover:bg-brand-700'
							}
						});
					}
				}
			})
			.catch(async (error) => {
				console.error('Error updating user name: ', error);
				await Swal.fire(
					'Operación cancelada',
					'No se realizaron cambios.',
					'info'
				);
			});
	};

	return (
		<MainSection title='' bgColor='bg-brand-25'>
			{/* <div className='my-8 w-full'>
				<div className='px-4 sm:px-6 lg:px-8'>
					<div></div>
					<hr />
				</div>
			</div> */}
			<main className='px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20'>
				<div className='mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none'>
					<div>
						<h2 className='text-base/7 font-semibold text-gray-900'>
							Datos de tu perfil
						</h2>
						<p className='mt-1 text-sm/6 text-gray-500'>
							Esta es la información que tenemos de tu perfil. Si deseas hacer
							algún cambio de tu data personal, puedes actualizarla aquí.
						</p>

						<dl className='mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm/6'>
							<div className='pt-6 sm:flex'>
								<dt className='font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6'>
									Primer nombre
								</dt>
								<dd className='mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto'>
									<div className='text-gray-900'>
										{currentUser.person?.firstName}
									</div>
									<button
										type='button'
										className='font-semibold text-brand-600 hover:text-brand-500'
										onClick={() => handleNameEdit('firstName')}>
										Actualizar
									</button>
								</dd>
							</div>
							<div className='pt-6 sm:flex'>
								<dt className='font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6'>
									Segundo nombre
								</dt>
								<dd className='mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto'>
									<div className='text-gray-900'>
										{currentUser.person?.middleName}
									</div>
									<button
										type='button'
										className='font-semibold text-brand-600 hover:text-brand-500'
										onClick={() => handleNameEdit('middleName')}>
										Actualizar
									</button>
								</dd>
							</div>
							<div className='pt-6 sm:flex'>
								<dt className='font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6'>
									Apellido
								</dt>
								<dd className='mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto'>
									<div className='text-gray-900'>
										{currentUser.person?.lastName}
									</div>
									<button
										type='button'
										className='font-semibold text-brand-600 hover:text-brand-500'
										onClick={() => handleNameEdit('lastName')}>
										Actualizar
									</button>
								</dd>
							</div>

							<div className='pt-6 sm:flex'>
								<dt className='font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6'>
									Segundo apellido
								</dt>
								<dd className='mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto'>
									<div className='text-gray-900'>
										{currentUser.person?.secondLastName}
									</div>
									<button
										type='button'
										className='font-semibold text-brand-600 hover:text-brand-500'
										onClick={() => handleNameEdit('secondLastName')}>
										Actualizar
									</button>
								</dd>
							</div>
							<div className='pt-6 sm:flex'>
								<dt className='font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6'>
									Email address
								</dt>
								<dd className='mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto'>
									<div className='text-gray-900'>{currentUser.email}</div>
									<button
										type='button'
										className='font-semibold text-brand-600 hover:text-brand-500'
										onClick={handleEmailChange}>
										Cambiar
									</button>
								</dd>
							</div>
							<div className='pt-6 sm:flex'>
								<dt className='font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6'>
									Cédula
								</dt>
								<dd className='mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto'>
									<div className='text-gray-900'>
										{currentUser.person?.cedula}
									</div>
									{/* <button
										type='button'
										className='font-semibold text-brand-600 hover:text-brand-500'>
										Update
									</button> */}
								</dd>
							</div>
							<div className='pt-6 sm:flex'>
								<dt className='font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6'>
									Fecha de nacimiento
								</dt>
								<dd className='mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto'>
									<div className='text-gray-900'>{currentUser.person?.dob}</div>
									{/* <button
										type='button'
										className='font-semibold text-brand-600 hover:text-brand-500'>
										Update
									</button> */}
								</dd>
							</div>
						</dl>
					</div>

					{/* <div>
								<h2 className='text-base/7 font-semibold text-gray-900'>
									Bank accounts
								</h2>
								<p className='mt-1 text-sm/6 text-gray-500'>
									Connect bank accounts to your account.
								</p>

								<ul
									role='list'
									className='mt-6 divide-y divide-gray-100 border-t border-gray-200 text-sm/6'>
									<li className='flex justify-between gap-x-6 py-6'>
										<div className='font-medium text-gray-900'>
											TD Canada Trust
										</div>
										<button
											type='button'
											className='font-semibold text-brand-600 hover:text-brand-500'>
											Update
										</button>
									</li>
									<li className='flex justify-between gap-x-6 py-6'>
										<div className='font-medium text-gray-900'>
											Royal Bank of Canada
										</div>
										<button
											type='button'
											className='font-semibold text-brand-600 hover:text-brand-500'>
											Update
										</button>
									</li>
								</ul>

								<div className='flex border-t border-gray-100 pt-6'>
									<button
										type='button'
										className='text-sm/6 font-semibold text-brand-600 hover:text-brand-500'>
										<span aria-hidden='true'>+</span> Add another bank
									</button>
								</div>
							</div> */}

					{/* <div>
								<h2 className='text-base/7 font-semibold text-gray-900'>
									Integrations
								</h2>
								<p className='mt-1 text-sm/6 text-gray-500'>
									Connect applications to your account.
								</p>

								<ul
									role='list'
									className='mt-6 divide-y divide-gray-100 border-t border-gray-200 text-sm/6'>
									<li className='flex justify-between gap-x-6 py-6'>
										<div className='font-medium text-gray-900'>QuickBooks</div>
										<button
											type='button'
											className='font-semibold text-brand-600 hover:text-brand-500'>
											Update
										</button>
									</li>
								</ul>

								<div className='flex border-t border-gray-100 pt-6'>
									<button
										type='button'
										className='text-sm/6 font-semibold text-brand-600 hover:text-brand-500'>
										<span aria-hidden='true'>+</span> Add another application
									</button>
								</div>
							</div> */}

					{/* <div>
								<h2 className='text-base/7 font-semibold text-gray-900'>
									Language and dates
								</h2>
								<p className='mt-1 text-sm/6 text-gray-500'>
									Choose what language and date format to use throughout your
									account.
								</p>

								<dl className='mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm/6'>
									<div className='pt-6 sm:flex'>
										<dt className='font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6'>
											Language
										</dt>
										<dd className='mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto'>
											<div className='text-gray-900'>English</div>
											<button
												type='button'
												className='font-semibold text-brand-600 hover:text-brand-500'>
												Update
											</button>
										</dd>
									</div>
									<div className='pt-6 sm:flex'>
										<dt className='font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6'>
											Date format
										</dt>
										<dd className='mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto'>
											<div className='text-gray-900'>DD-MM-YYYY</div>
											<button
												type='button'
												className='font-semibold text-brand-600 hover:text-brand-500'>
												Update
											</button>
										</dd>
									</div>
								</dl>
							</div> */}
				</div>
			</main>
		</MainSection>
	);
};
export default UserProfile;
