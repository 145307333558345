interface FooterSectionProps {
	children?: React.ReactNode;
	title: string;
}
const FooterSection = ({ children, title }: FooterSectionProps) => {
	return (
		// THIS IS ONE COLUMN / SECTION
		<div className='p-5 w-1/2 sm:w-4/12 md:w-3/12'>
			{/* <!-- SECTION TITLE --> */}
			<div className='text-xs uppercase text-gray-400 font-medium mb-6'>
				{title}
			</div>
			{/* SECTION LINKS */}
			{children}
		</div>
	);
};

export default FooterSection;
