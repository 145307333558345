import { createContext, useContext, useState } from 'react';
import type { defaultContextType } from '../context/types';
import type {
	TestRecord,
	Question,
	Category,
	IQuestionBankReducerState
} from '../data/interface-question';
import useUserContext from './UserContext';
import { db, handleEventLog } from '../gcp/config';
import {
	collection,
	doc,
	getDoc,
	getDocs,
	addDoc,
	setDoc,
	updateDoc,
	query,
	where,
	// or,
	deleteField
} from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import {
	createObjectStore,
	deleteDB,
	deleteValue,
	getAllValues,
	putBulkValues,
	putValue
} from '../data/indexedDBHelper';
import type { IDBPDatabase } from 'idb';
import {
	buildCategoryObject,
	buildProductDataObject,
	buildQuestionObject,
	buildTestRecordObject
} from './xBuildObjectUtils';
import {
	calcTestGrade,
	getAverageGrade,
	convertFirebaseTSToDateString
} from '../utils/helpers';
import type { FirebaseError } from 'firebase/app';
import Swal from 'sweetalert2';
import type { UnfinishedTestRecord, User } from '../data/interface-user';
import { ProductData, QuestionBankID } from '../data/interface-question-bank';
export type QuestionBankContextType = {
	loadRequiredData: () => Promise<void>;
	// CATEGORIES
	categories: Category[] | undefined;
	getCategories: () => Promise<Category[]>;
	// GET QUESTIONS
	questions: Question[] | undefined;
	setQuestions: (v: Question[] | undefined) => void;
	uploaderProgress: number;
	setUploaderProgress: (v: number) => void;
	getQuestion: (id: string) => Promise<Question | null>;
	getQuestions: () => Promise<Question[]>;
	getQuestionImageURL: (path: string) => Promise<string>;
	getQuestionAudioURL: (path: string) => Promise<string>;
	// TEST SELECTIONS
	testSelections: IQuestionBankReducerState | undefined;
	saveTestSelections: (v: UnfinishedTestRecord) => Promise<void>;
	setTestSelections: (v: IQuestionBankReducerState | undefined) => void;
	updateUnfinishedTestOnNextQuestion: (i: number, s: string) => Promise<void>;
	deleteUnfinishedTest: () => Promise<void>;
	// GET TEST RECORDS
	testRecords: TestRecord[] | null;
	setTestRecords: (v: TestRecord[] | null) => void;
	getTestRecords: () => Promise<TestRecord[] | null>;
	saveTestRecord: (testRecord: TestRecord) => Promise<boolean>;
	updateQuestionbyKey: (
		questionID: string,
		key: string,
		newValue: any
	) => Promise<void>;
	updateTestRecordName: (id: string, name: string) => Promise<void>;
};

// CREATE THE CONTEXT
export const QuestionBankContext =
	createContext<QuestionBankContextType | null>(null);

// CONTEXT PROVIDER
export const QuestionBankContextProvider = ({
	children
}: defaultContextType) => {
	const { currentUser, setCurrentUser, product } = useUserContext();
	const [categories, setCategories] = useState<Category[] | undefined>();
	const [questions, setQuestions] = useState<Question[] | undefined>();
	const [testRecords, setTestRecords] = useState<TestRecord[] | null>(null);
	const [uploaderProgress, setUploaderProgress] = useState<number>(0);
	const [testSelections, setTestSelections] = useState<
		IQuestionBankReducerState | undefined
	>();

	// TODO: Move these to a constants file
	const TODAY = Date.now();
	const ONE_DAY_IN_SECONDS = 86400;
	const THIRTY_DAYS = ONE_DAY_IN_SECONDS * 30;
	// TODO: add other constanst (look at flashcards context)
	// TODO: Re-enable webAppVersion when it's available
	// let version = webAppVersion.number * 100;
	const VERSION = 2 * 100;

	// #region IDBP HELPERS
	/**
	 * Opens an IndexedDB connection
	 */
	const openDBConnection = async (
		dbName = 'prepmed-questions',
		tableNamesArray = ['questions']
	) => {
		let getDB: IDBPDatabase<unknown> | undefined;
		if (VERSION > 0) {
			getDB = await createObjectStore(dbName, VERSION, tableNamesArray);
		}
		return getDB;
	};
	// #endregion

	// #region CATEGORIES
	// TODO: Get categories for user.examTarget
	const getCategories = async (): Promise<Category[]> => {
		let data: Category[] = [];
		const pullDate = localStorage.getItem('prepmed-last-categories-pull-date');
		let lastPullDate: number | null;
		const lsCategories = localStorage.getItem('prepmed-categories');
		let localStorageCategories: Category[] | null;

		if (currentUser !== null) {
			if (lsCategories === null || pullDate === null) {
				data = await getCategoriesCollection();
			} else {
				localStorageCategories = JSON.parse(lsCategories);
				lastPullDate = Number(JSON.parse(pullDate));

				// CLEAR LOCAL STORAGE IF PAST 30 DAYS
				if (currentUser.isAdmin ?? false) {
					if ((TODAY - lastPullDate) / 1000 > ONE_DAY_IN_SECONDS) {
						console.log("Clearing admin's local storage");
						localStorage.removeItem('prepmed-categories');
						localStorage.removeItem('prepmed-last-questions-pull-date');
						localStorageCategories = null;
					}
				} else if ((TODAY - lastPullDate) / 1000 > THIRTY_DAYS) {
					localStorage.removeItem('prepmed-categories');
					localStorage.removeItem('prepmed-last-questions-pull-date');
					localStorageCategories = null;
				} else {
					// todo: if usr not an admin and not 30 days????
				}

				if (localStorageCategories !== null) {
					localStorageCategories.forEach((c) => {
						const cat = buildCategoryObject(c.id, c);
						if (cat !== null) {
							data.push(cat);
						}
					});
				} else {
					if (categories !== undefined && categories.length > 0) {
						data = categories;
						return data;
					} else {
						data = await getCategoriesCollection();
					}
				}
			}
		} else {
			// TODO: Handle no user found
		}

		setCategories(data);
		return data;
	};

	/**
	 * Gets all categories from Firestore
	 */
	const getCategoriesCollection = async (): Promise<Category[]> => {
		const data: Category[] = [];
		if (currentUser === null) return data;
		const { examTarget } = currentUser;
		const categoriesRef = collection(db, 'categories');
		const q = query(categoriesRef, where('questionBank', '==', examTarget));
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach((c) => {
			const cat = buildCategoryObject(c.id, c.data());
			if (cat !== null) {
				data.push(cat);
			}
		});

		// SET TO LOCAL STORAGE
		localStorage.setItem('prepmed-categories', JSON.stringify(data));
		localStorage.setItem(
			'prepmed-last-categories-pull-date',
			JSON.stringify(TODAY)
		);
		return data;
	};
	// #endregion

	// TODO: Update the question gets with currentUser.exam to filter questions
	// #region GET QUESTIONS
	/**
	 * Gets a single question and updates the questions object/state
	 */
	const getQuestion = async (id: string) => {
		let question: Question | null = null;
		const questionRef = doc(db, 'questions', id);
		const docSnap = await getDoc(questionRef);

		if (docSnap.exists()) {
			const data = { ...docSnap.data() };
			question = buildQuestionObject(id, data);

			const thisDB = await openDBConnection();
			if (thisDB === undefined) return null;
			// await deleteValue(thisDB, 'questions', id);
			await putValue(thisDB, 'questions', {
				...question
			});
		} else {
			question = null;
		}

		return question;
	};

	/**
	 * Main logic to get all questions
	 */
	const getQuestions = async () => {
		let data: Question[] = [];
		if (currentUser === null) return data;
		const localDate: string | null = localStorage.getItem(
			'prepmed-last-questions-pull-date'
		);
		const lastPullDate: number | null =
			localDate !== null ? Number(JSON.parse(localDate)) : null;

		// DEFINES WHEN TO CLEAR LOCAL STORAGE
		const clearLocalStorage = async () => {
			if (lastPullDate !== null) {
				if (currentUser?.isAdmin ?? false) {
					if ((TODAY - lastPullDate) / 1000 > ONE_DAY_IN_SECONDS) {
						localStorage.removeItem('prepmed-last-questions-pull-date');
						await deleteDB('prepmed-questions');
					}
				} else {
					if ((TODAY - lastPullDate) / 1000 > THIRTY_DAYS) {
						localStorage.removeItem('prepmed-last-questions-pull-date');
						await deleteDB('prepmed-questions');
					}
				}
			}
			return localStorage;
		};
		await clearLocalStorage();

		// GET THE QUESTIONS
		const thisDB = await openDBConnection();
		if (thisDB !== undefined) {
			data = await getAllValues(thisDB, 'questions');
		}
		const hasLocalData = data.length > 0;
		const isFreeTrialUser: boolean = currentUser.freeTrial?.isActive ?? false;
		if (!hasLocalData) {
			if (questions !== undefined && questions.length > 0) {
				// GET QUESTIONS FROM USESTATE
				data = questions;
			} else {
				// GET QUESTIONS FROM FIRESTORE
				data = await getQuestionsCollection();
			}
		}

		// SET QUESTIONS TO STATE, REDUCER AND LOCAL STORAGE
		if (isFreeTrialUser) {
			data = filterFreeTrialQuestions(data);
		}

		setQuestions(data);
		if (thisDB !== undefined && data.length > 0) {
			await putBulkValues(thisDB, 'questions', data);
		} else {
			console.error('No local data found');
		}
		//  DATA BACKUP - HAS TO BE DONE MANUALLY
		// if (DATABASE_BACKUP) {
		// fbQ = JSON.stringify([...data]);

		// let day = Date.now();
		// let today = new Date(day);
		// questionsBackupDownload(
		// fbQ,
		// `QUESTIONS_BACKUP ${
		// today.getMonth() + 1
		// }-${today.getDate()}-${today.getFullYear()}.json`,
		// 'text/plain'
		// );
		// }

		return data;
	};

	/**
	 * Gets all questions from Firestore
	 */
	const getQuestionsCollection = async (): Promise<Question[]> => {
		const data: Question[] = [];
		if (currentUser === null) return data;
		const { examTarget } = currentUser;
		const questionsRef = collection(db, 'questions');
		const newQuery = query(questionsRef, where('bank', '==', examTarget));

		const querySnapshot = await getDocs(newQuery);
		querySnapshot.forEach((q) => {
			const question = buildQuestionObject(q.id, q.data());
			if (question !== null) {
				data.push(question);
			}
		});

		localStorage.setItem(
			'prepmed-last-questions-pull-date',
			JSON.stringify(TODAY)
		);
		return data;
	};

	const filterFreeTrialQuestions = (data: Question[]) => {
		let filteredData: Question[] = [];
		if (currentUser !== null) {
			const { examTarget } = currentUser;
			if (examTarget !== undefined && examTarget === 'enurm') {
				filteredData = data.filter((q) => {
					return q.source === 'enurm-2020';
				});
			}

			// TODO: Add filters for maxilofacial and dermatologia
		} else {
			console.error('No user found to filter questions by exam target');
		}

		return filteredData;
	};

	// TODO: HANDLE DOWNLOAD ERRORS
	// https://firebase.google.com/docs/storage/web/download-files
	const getQuestionImageURL = async (path: string) => {
		let downloadURL = '';
		const storage = getStorage();
		const pathReference = ref(storage, `question-images/${path}`);

		// let thisPath = await pathReference.getDownloadURL();
		// return thisPath;
		await getDownloadURL(pathReference)
			.then((url) => {
				// `url` is the download URL for 'images/stars.jpg'
				downloadURL = url;
				// // This can be downloaded directly:
				// const xhr = new XMLHttpRequest();
				// xhr.responseType = 'blob';
				// xhr.onload = (event) => {
				// const blob = xhr.response;
				// };
				// xhr.open('GET', url);
				// xhr.send();

				// // Or inserted into an <img> element
				// const img = document.getElementById('myimg');
				// img?.setAttribute('src', url);
			})
			.catch((error: FirebaseError) => {
				// Handle any errors
				// https://firebase.google.com/docs/storage/web/handle-errors
				console.error('IMAGE_DOWNLOAD_ERROR', {
					...error,
					imagePathName: path
				});
				handleEventLog('IMAGE_DOWNLOAD_ERROR', {
					...error,
					imagePathName: path
				});
			});

		return downloadURL;
	};

	// TODO: HANDLE DOWNLOAD ERRORS
	// https://firebase.google.com/docs/storage/web/download-files
	const getQuestionAudioURL = async (path: string) => {
		let downloadURL = '';
		const storage = getStorage();
		const pathReference = ref(storage, `question-audio-files/${path}`);

		// let thisPath = await pathReference.getDownloadURL();
		// return thisPath;
		await getDownloadURL(pathReference)
			.then((url) => {
				// `url` is the download URL for 'images/stars.jpg'
				downloadURL = url;
				// // This can be downloaded directly:
				// const xhr = new XMLHttpRequest();
				// xhr.responseType = 'blob';
				// xhr.onload = (event) => {
				// const blob = xhr.response;
				// };
				// xhr.open('GET', url);
				// xhr.send();

				// // Or inserted into an <img> element
				// const img = document.getElementById('myimg');
				// img?.setAttribute('src', url);
			})
			.catch((error) => {
				// Handle any errors
				console.error('AUDIO_DOWNLOAD_ERROR', {
					...error,
					audioPathName: path
				});
				handleEventLog('AUDIO_DOWNLOAD_ERROR', {
					...error,
					audioPathName: path
				});
			});

		return downloadURL;
	};
	// #endregion

	// #region GET TEST RECORDS
	const getTestRecords = async (): Promise<TestRecord[] | null> => {
		const userTestRecords: TestRecord[] = [];
		if (currentUser === null) {
			return null;
		} else {
			// TODO: ENHANCEMENT - Compound queries require composite indexes - see https://firebase.google.com/docs/firestore/query-data/queries#compound_and_queries

			const { uid, examTarget, examTargetYear } = currentUser;
			const queryCollection = 'testRecords';

			if (queryCollection === null) {
				// TODO: Handle no query collection found
			} else {
				const recordsRef = collection(db, queryCollection);
				const q = query(
					recordsRef,
					where('userID', '==', uid),
					where('examTarget', '==', examTarget),
					where('examTargetYear', '==', examTargetYear)
				);

				const querySnapshot = await getDocs(q);
				querySnapshot.forEach((record) => {
					const r = buildTestRecordObject(record.id, record.data());
					if (r !== null) userTestRecords.push(r);
				});
			}
		}

		setTestRecords(userTestRecords);
		return userTestRecords;
	};

	const saveTestRecord = async (testRecord: TestRecord): Promise<boolean> => {
		// SAVES TEST RESULTS AS AN ARRAY WITH NESTED OBJECTS
		// const previousTestResults = localStorage.getItem(
		// 'prepmed-user-test-records'
		// )
		// ? JSON.parse(localStorage.getItem('prepmed-user-test-records'))
		// : [];
		// console.info('ls records:', previousTestResults);

		if (currentUser === null) return false;

		const newRecord = buildTestRecordObject(undefined, {
			...testRecord,
			userID: currentUser.uid,
			date: new Date(),
			examTargetYear: currentUser.examTargetYear,
			examTarget: currentUser.examTarget
		});

		if (newRecord === null) return false;
		// const testRecordsCollection = `testRecords_${product.nextExamTargetYear}`;
		const testRecordsCollection = 'testRecords';
		const testRecordRef = await addDoc(collection(db, testRecordsCollection), {
			...newRecord
		});

		// console.log('Document written with ID: ', testRecordRef.id);
		// todo: should I add to local storage?
		// localStorage.removeItem('prepmed-user-test-records');
		// localStorage.setItem(
		// 'prepmed-user-test-records',
		// JSON.stringify([
		// ...previousTestResults,
		// {
		// ...currentTestResults,
		// date: { seconds: Math.floor(Date.now() / 1000) }
		// }
		// ])

		// let allRecords = [
		// ...previousTestResults,
		// {
		// ...currentTestResults,
		// date: { seconds: Math.floor(Date.now() / 1000) }
		// }
		// ];

		// //~ UPDATE USER RECORDS
		await updUserTestRecords(newRecord);

		if (testRecords === null) return false;
		setTestRecords((prevRecords) =>
			prevRecords === null
				? [{ ...newRecord, id: testRecordRef.id }]
				: [...prevRecords, { ...newRecord, id: testRecordRef.id }]
		);
		return true;
	};

	const updUserTestRecords = async (record: TestRecord) => {
		if (currentUser === null || currentUser.uid === undefined) return null;

		const lastGrade = calcTestGrade(record.correctAnswers, record.testLength);
		const allRecords =
			testRecords === null ? [record] : [...testRecords, record];
		const averageGrade = await getAverageGrade(allRecords);
		const testScores =
			currentUser?.testRecordSummary?.testScores === undefined
				? [lastGrade]
				: [...currentUser.testRecordSummary?.testScores, lastGrade];

		// UPDATE USER RECORDS
		const userRef = doc(db, 'users', currentUser.uid);
		await updateDoc(userRef, {
			testRecordSummary: {
				lastTestDate: record.date,
				lastTestGrade: lastGrade,
				testScores,
				averageGrade
			}
		});
	};
	// #endregion

	const loadRequiredData = async () => {
		await getCategories();
		await getQuestions();
		await getTestRecords();
	};

	/**
	 * Updates a question field, takes 3 params: questionID, fieldKey & newValue
	 */
	const updateQuestionbyKey = async (
		questionID: string,
		key: string,
		newValue: any
	) => {
		// dispatch({
		// type: 'updateQuestions',
		// payload: [qID, key, newValue]
		// });

		// UPDATE USER RECORDS
		const editor = currentUser === null ? '' : currentUser.uid;
		const userRef = doc(db, 'questions', questionID);
		await updateDoc(userRef, {
			[key]: newValue,
			lastEditDate: new Date(),
			lastEditBy: editor
		});

		await getQuestion(questionID);
		// let thisQuestion = questions.find(
		// (question) => question.id === questionID
		// );

		// let updatedQuestion = {
		// ...thisQuestion[0],
		// [keyToChange]: newValue
		// };

		// questions: [
		// ...state.questions.filter((q) => {
		// return q.id !== questionID;
		// }),
		// updatedQuestion
		// ];
	};

	/**
	 * Updates a question field, takes 3 params: questionID, fieldKey & newValue
	 */
	const updateTestRecordName = async (id: string, name: string) => {
		// UPDATE USER RECORDS
		const editor = currentUser === null ? '' : currentUser.uid;
		const testRecordRef = doc(db, 'testRecords', id);
		await updateDoc(testRecordRef, {
			testName: name,
			lastEditDate: new Date(),
			lastEditBy: editor
		});

		await getTestRecords();
	};

	// #region UNFINISHED TESTS
	const saveTestSelections = async (selections: UnfinishedTestRecord) => {
		const id = currentUser?.uid ?? undefined;
		if (id === undefined) {
			await Swal.fire({
				title: 'Error',
				text: 'No podemos guardar las selecciones para tu examen en este momento...',
				icon: 'error'
			});
		} else {
			const unfinishedTest: UnfinishedTestRecord = {
				// ...selections,
				allQuestions: [],
				categories: [],
				testRecords: [],
				especialidades: selections.especialidades,
				label: selections.label,
				questionType: selections.questionType,
				questions: [],
				questionIDs: selections.questionIDs,
				showCategorySection: selections.showCategorySection,
				showTopicSelectionBoxes: selections.showTopicSelectionBoxes,
				testDuration: selections.testDuration,
				testLabel: selections.testLabel,
				testLength: selections.testLength,
				testMode: selections.testMode,
				testType: selections.testType,
				timerStartTime: selections.timerStartTime,
				topics: selections.topics,
				topicSubcategories: selections.topicSubcategories,
				useTimer: selections.useTimer,
				useTutorMode: selections.useTutorMode,
				userCorrectQuestions: [],
				userIncorrectQuestions: [],
				isUnfinishedTest: true,
				currentQuestionIndex: 0,
				answersSelected: []
			};

			const userRef = doc(db, 'users', id);
			await updateDoc(userRef, {
				unfinishedTest
			});

			setCurrentUser((prev: User | null) => {
				if (prev === null) return prev;
				return {
					...prev,
					unfinishedTest
				};
			});
		}
	};

	const updateUnfinishedTestOnNextQuestion = async (
		questionIndex: number,
		selection: string
	) => {
		const id = currentUser?.uid ?? undefined;
		if (id === undefined) {
			await Swal.fire({
				title: 'Error',
				text: 'No podemos guardar las selecciones para tu examen en este momento...',
				icon: 'error'
			});
		} else {
			const prevAnswers = currentUser?.unfinishedTest?.answersSelected ?? [];
			const answers = [...prevAnswers, selection];
			const userRef = doc(db, 'users', id);
			await updateDoc(userRef, {
				'unfinishedTest.currentQuestionIndex': questionIndex,
				'unfinishedTest.answersSelected': [...answers]
			});

			setCurrentUser((prev: User | null) => {
				if (prev === null || prev.unfinishedTest === undefined) return prev;
				return {
					...prev,
					unfinishedTest: {
						...prev.unfinishedTest,
						currentQuestionIndex: questionIndex,
						answersSelected: [...prevAnswers, selection]
					}
				};
			});
		}
	};

	const deleteUnfinishedTest = async () => {
		const id = currentUser?.uid ?? undefined;
		if (id === undefined) {
			await Swal.fire({
				title: 'Error',
				text: 'No podemos guardar los cambios en este momento...',
				icon: 'error'
			});
		} else {
			const userRef = doc(db, 'users', id);
			await updateDoc(userRef, {
				unfinishedTest: deleteField()
			});

			setCurrentUser((prev: User | null) => {
				if (prev === null) return prev;
				const clone = Object.assign({}, prev);
				delete clone.unfinishedTest;
				return {
					...clone
				};
			});
		}
	};
	// #endregion

	/**
	 * Main logic to get all questions
	 */
	// const getProducts = async () => {
	// let data: ProductData[] = [];
	// if (currentUser === null) return data;
	// const productsRef = collection(db, 'products');
	// const q = query(
	// productsRef,
	// or(
	// where('questionBank', '==', 'enurm'),
	// where('questionBank', '==', 'maxilofacial'),
	// where('questionBank', '==', 'dermatologia')
	// )
	// );
	// const querySnapshot = await getDocs(q);
	// querySnapshot.forEach((c) => {
	// const product = buildProductDataObject(c.id as QuestionBankID, c.data());
	// if (product !== null) {
	// data.push(product);
	// }
	// });

	// return data;
	// };

	return (
		<QuestionBankContext.Provider
			value={{
				deleteUnfinishedTest,
				updateUnfinishedTestOnNextQuestion,
				loadRequiredData,
				categories,
				getCategories,
				questions,
				setQuestions,
				updateQuestionbyKey,
				updateTestRecordName,
				uploaderProgress,
				setUploaderProgress,
				getQuestion,
				getQuestions,
				getQuestionAudioURL,
				getQuestionImageURL,
				testSelections,
				saveTestSelections,
				setTestSelections,
				testRecords,
				setTestRecords,
				getTestRecords,
				saveTestRecord
			}}>
			{children}
		</QuestionBankContext.Provider>
	);
};

// CUSTOM HOOK
export const useQuestionBankContext = () => {
	const context = useContext(QuestionBankContext);
	if (context === undefined || context === null) {
		throw new Error(
			'useQuestionBankContext must be used inside a CounterProvider'
		);
	}
	return context;
};

export default useQuestionBankContext;
